const courseSubmitFormValidation = () => {

  const form = document.getElementById('extra-info-form');
  const payButton = document.getElementById('pay-button');

  // Fonction pour vérifier si tous les champs sont remplis
  const checkFormFields = () => {
    const firstName = document.getElementById('first_name').value.trim();
    const lastName = document.getElementById('last_name').value.trim();
    const email = document.getElementById('email').value.trim();
    const phoneNumber = document.getElementById('phone_number').value.trim();

    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Vérifiez si tous les champs sont remplis et valides
    return firstName && lastName && emailRegex.test(email) && phoneNumber;
  };

  // Désactiver le bouton au chargement de la page
  const disablePayButton = () => {
    if (payButton) {
      payButton.disabled = true;
      payButton.classList.add('btn-disabled');
    };
  }

  // Gérer les événements d'entrée pour chaque champ
  if (form) {
    form.addEventListener('input', () => {
      if (checkFormFields()) {
        payButton.disabled = false;
        payButton.classList.remove('btn-secondary');
      } else {
        disablePayButton();
      }
    });
  }

  // Désactivez le bouton lors de l'initialisation du script
  disablePayButton();
};
  
export { courseSubmitFormValidation };
